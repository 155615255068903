import React from "react";
import SingleDropdown from "./SingleDropdown";

const FAQ = () => {
	return (
		<div class="bg-gray-50">
			<div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
				{/* <h2 class="text-center font-semibold tracking-wider text-main-600 uppercase">
          Allt, och lite till.
        </h2> */}
				<div class="max-w-3xl mx-auto">
					<h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
						Vanliga frågor och svar
					</h2>
					<SingleDropdown
						text="Hur kommer vi igång med kontorsstädning? "
						description="Vi börjar alltid med ett kostnadsfritt startmöte som sker på ert kontor eller digitalt via videosamtal, du väljer!  Boka ett kostnadsfritt startmöte här på vår hemsida eller kontakta vår kundservice via e-mail eller telefon."
					/>
					<SingleDropdown
						text="Vad ingår i kontorsstädningen?"
						description="Vi anpassar oss efter era behov och önskemål så berätta vad ni vill ska ingå! "
					/>
					<SingleDropdown
						text="Hur ofta behöver man städa ett kontor?"
						description="De flesta av våra kontorskunder har städning en gång i veckan men vi utgår från era behov, ytor och antal anställda när vi städar hos er. "
					/>
					<SingleDropdown
						text="Erbjuder ni andra tjänster än städning?"
						description="Absolut! Vi kan hjälpa er med storstädning, fönsterputs och vattna blommor fönsterputs och  Vi kan hjälpa er med Fönsterputs, Storstädning, Enklare hantverk, Återvinning, Trädgårdshjälp och Kontorsflytt. Vissa tjänster finns på utvalda orter."
					/>
				</div>
			</div>
		</div>
	);
};

export default FAQ;
