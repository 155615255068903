import React, { useEffect, useState } from "react";
import { Seo } from "../../../components/seo";
import Layout from "../../../hoc/Layout/Layout";
import Booking from "../../../components/Booking/Booking";
import Instructions from "../../../components/Instructions/Instructions";
import GradeSection from "../../../components/GradeSection/GradeSection";
import Faq from "../../../components/FAQ/FAQKontorsstadning";
import AdvantagesSection from "../../../components/AdvantagesSection/AdvantagesSection";
import { SERVICE_TYPES } from "../../../enums/serviceTypes";
import { navigate } from "gatsby";

// markup
const IndexPage = () => {
	const [bookingActive, setBookingActive] = useState(false);
	const [serviceChosen, setServiceChosen] = useState(false);
	const [ableToGoBack, setAbleToGoBack] = useState(false);
	const [finishBookingStep, setFinishBookingStep] = useState();

	useEffect(() => {
		const city = localStorage.getItem('city');
		if (city !== 'linkoping') {
			navigate('/tjanster/kontorsstadning')
		}
	}, []);

	return (
		<Layout
			bookingActive={bookingActive}
			serviceChosen={serviceChosen}
			ableToGoBack={ableToGoBack}
			finishBookingStep={finishBookingStep}
			setFinishBookingStep={setFinishBookingStep}
		>
			<Seo title="Kontorsstädning Linköping | DreamClean" />
			<Booking
				bgType4
				setBookingActive={setBookingActive}
				choosenService={SERVICE_TYPES.Kontorsstädning}
				firstStep={2}
				setServiceChosen={setServiceChosen}
				setAbleToGoBack={setAbleToGoBack}
				finishBookingStep={finishBookingStep}
				setFinishBookingStep={setFinishBookingStep}
			/>
			{!bookingActive && (
				<>
					<AdvantagesSection
						type={SERVICE_TYPES.Kontorsstädning}
						text1={{
							heading: "Socialt ansvarstagande hemstädningsföretag",
							description:
								"Vi har kollektivavtal med Almega och våra anställda får självklart betald semester, pension, friskvårdsbidrag och andra förmåner.",
						}}
						text2={{
							heading: "Inga bindningstider",
							description:
								"Våra kunder stannar för att tjänsten är bra, inte på grund av långa bindningstider.  Pausa ert abonnemang när ni vill, ni är alltid välkomna tillbaka.",
						}}
						text3={{
							heading: "Alltid samma städare",
							description:
								"En av våra utbildade städproffs väljs noggrant ut och städar hos er samma tid och samma veckodag. Detta för att lära känna ert kontor och utföra högsta kvalitet av städning. ",
						}}
						text4={{
							heading: "Ansvarsförsäkring",
							description:
								"Du kan känna dig trygg med att vi har en försäkring som täcker eventuella skador ifall en olycka skulle framkomma. Såklart är alla våra anställda också fullt försäkrade.",
						}}
					/>
					<Instructions
						// bg
						text1="Ange företagets postnummer och hur stor yta som behöver städas."
						text2="Se pris och boka ett kostnadsfritt startmöte den tid som passar dig där vi går igenom städupplägg tillsammans."
						text3="Er städare kommer till er vid önskad tidpunkt och ni får njuta av ett rent och fräscht kontor"
						heading1="Berätta om ditt kontor"
						heading2="Välj en tid som passar dig"
						heading3="Njut av ett rent kontor"
					/>
					<GradeSection />
					<Faq />
				</>
			)}
		</Layout>
	);
};

export default IndexPage;
